import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const CustomizedWigforWomen = () => {

  const seoData = {
    title: 'Natural Look Customized Wigs For Women In Delhi - Radiance',
    description: 'Natural Look Customized Wigs For Women In Delhi at Radiance Hair Studio. Find the perfect wig to enhance your style and confidence. Visit us today.',
    keywords: ['Customized Wigs For Women, Customized Wigs For Women in Delhi, Customized Wigs for Women Price, Customized Wigs For Women Near Me']
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/customized-wig-women.png",
      "https://www.radiancehairstudio.com/customized-wig-women1.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/customized-wigs-for-women.php",
    "category": "https://www.radiancehairstudio.com/customized-wigs-for-women.php",
    "description": "Natural Look Customized Wigs For Women In Delhi at Radiance Hair Studio. Find the perfect wig to enhance your style and confidence. Visit us today.",
    "disambiguatingDescription": "Customized Wigs For Women, Customized Wigs For Women in Delhi, Customized Wigs for Women Price, Customized Wigs For Women Near Me",
    "serviceType": "Customized Wig for Women"
  };  

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Customized-Wig-for-Women.jpg",
      content: (
        <div>
          <h2>Affordable Customized Wig for Women's Delhi</h2>
          <p>
          If you are experiencing excessive hair loss or anything associated with it, wigs might be worn. Wigs that are custom-made can be manufactured to your specifications and, more importantly, to fit you precisely!.. 
          </p>
          <h2>Complete Natural Hair, Just Like Yours!</h2>
          <p>
          You are still imagining that how it's possible to get the perfect wig which can reflect your mood or maybe your personality. Leave that on RADIANCE HAIR STUDIO.No matter what, they've got your back! Simply let them know how you would like your hair to look, and they will undoubtedly have the greatest assortment of wigs or even if they are unable to locate the precise one. Don't worry! We will create your wig or, if you choose, your natural hairThey will create a brown wig that will appear exactly like your natural hair if you have brown hair and you don't want to change the shade since you think it suits you. I promise you that you will adore it.
          </p>
          <p>
          Although we already have a huge assortment of wigs at <a href="/" style={{ color: "#373737"}}> <strong>RADIANCE HAIR STUDIO</strong></a>, they always put their customers first so they have come with this exclusive custom made wig thing!. Apart from this, there's another reason to choose RADIANCE as their wigs are made of 100 percent natural human hair which is actually a great thing. 

          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/customized-wig-women.png",
      largeImg: "/customized-wig-women1.png",
      content: (
        <div>
          <h2>Stay Stylish with Custom Wigs</h2>
          <p>
          We all know how wigs can be a lifesaver, but there’s a huge difference between standard wigs and custom-made wigs. Here’s why choosing a personalized wig is the best decision you can make:
          </p>
          <ul>
            <li>
              <b>Perfect Color Match</b>: Generic wigs don’t always blend well with your natural hair color. At RADIANCE HAIR STUDIO, we offer a wide range of shades to ensure you get a wig that perfectly complements your natural tone.
            </li>
            <li>
              <b>Designed for Your Hair Type</b>: Whether your hair is curly, wavy, or straight, a custom-made wig is tailored specifically for you. It seamlessly mimics your natural hair texture, ensuring an effortless and flawless look.
            </li>
            <li>
              <b>Choose Your Ideal Length</b>: Not everyone wants the same hair length every day. Since our wigs are crafted from 100% natural human hair, you can confidently rock short, medium, or long styles—whatever suits your mood!
            </li>
            <li>
              <b>A Flawless Fit</b>: One-size-fits-all wigs can feel uncomfortable or slip out of place. With a custom wig, you get a secure, natural fit that feels just like your own hair.
            </li>
          </ul>
          <p>
          All the doubts holding you back from trying a natural human hair wig are now gone! Take charge of your style and embrace confidence with the perfect wig. Get yours today and redefine your look!
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "Frequently Asked Questions About Custom-Made Wigs",
      description:
        "Many people have questions when it comes to wigs, especially custom-made ones. These are responses to some of the most frequently asked questions",
      description2: "Looking for the perfect wig? Radiance Hair Studio is here to help you find the best fit for your needs!",
      faq: [
        {
          title: "Should I buy more than one wig?",
          description:
            "If you're opting for a custom-made wig, having multiple wigs may not be necessary. A custom wig is designed to match your natural hair perfectly, providing a seamless look. At Radiance Hair Studio, our certified hair experts will guide you in selecting the ideal wig to suit your needs.",
        },
        {
          title: "Which type of wigs look the most natural?",
          description:
            "To achieve the most natural appearance, avoid synthetic wigs. Always choose a wig made from 100% natural human hair for a realistic look. At Radiance Hair Studio, we offer high-quality, natural wigs that blend seamlessly with your own hair.",
        },
        {
          title:
            "Do I need a full wig for thinning hair or partial hair loss?",
          description:
            "If you have thinning hair or partial hair loss, a topper is an option, but it may not always be the best solution. A custom-made wig using 100% human hair can provide a more natural and fuller look. Our experts can create a wig tailored to your specific hair needs.",
        },
        {
          title:
            "What is the difference between a ready-made wig and a custom-made wig?",
          description:
            "While ready-made wigs can be convenient, a custom-made wig offers a personalized fit that matches your hair color, texture, and style preferences. It is crafted exclusively for you, ensuring a look that feels completely natural and unique.",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Customized Wig for Women" banner="/customized-wig-for-women.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Affordable Customized Wig for Women's Delhi" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default CustomizedWigforWomen;
